.App {
  	text-align: center;
	background-color: rgb(145 144 144);
	width: 100vw;
	
	display: flex;
	flex-direction: column;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-table-thead tr th {
  background-color: #4e555e !important;
  color: #ffffff !important;
  font-weight: bold !important; 
  text-align: center !important;
  padding: 12px 16px !important; 
}


.ant-table .even-row {
  background-color: #dfdede !important;
}

.ant-table .odd-row {
  background-color: #ffffff !important;
}

.ant-table-column-sort {
  background-color: transparent !important;  /* 기본 배경색을 없애기 */
  color: inherit !important;                 /* 기본 색상을 그대로 유지 */
}